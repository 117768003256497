<template>
  <div>
    <div class="flex justify-center">
      <div class="w-3/4">
        <div v-if="validationAsset" class="validation-asset">
          <p class="decode-result text-black">
            <b>{{ result }}</b>
          </p>
          <fieldset class="mt-3 border-t-1 border-black">
  <legend class="sr-only">Actions</legend>

  <div :class="'flex items-center px-2 py-2 mb-2 mt-2 border-black border bg-' +button[2]+ '-600'"  v-for="(button, bIndex) in buttons" :key="button[1]">
    <input :id="'country-option-'+button[1]" type="radio" name="countries" @change="optionChosenAction($event)" v-model="optionChosen" :value="bIndex" class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300" :aria-labelledby="'country-option-'+button[1]" :aria-describedby="'country-option-'+button[1]" >
    <label :for="'country-option-'+button[1]" class="text-sm font-medium text-gray-900 ml-2 block w-full text-left">
      {{ button[0] }}
    </label>
  </div>
          </fieldset>
          <button
            v-if="needsPhoto || choiceNeedsPhoto"
            class="btn-red"
            @click="takePhoto = true"
          >
            <font-awesome-icon class="mr-3" icon="camera" />Take photo
          </button>
          <button v-if="canSaveUUID" class="btn-black" @click="saveQR">
              <font-awesome-icon class="mr-3" icon="power-off" />Save
            </button>
          
        </div>
        <EasyCamera
          ref="cam"
          v-if="takePhoto"
          @input="processPicture"
          @close="closePhoto"
          v-model="photo"
          :mustApprove="true"
          :fullscreen="true"
        ></EasyCamera>

        <qrcode-stream
          v-if="!takePhoto && !validationAsset"
          id="my-video-id"
          :camera="camera"
          @decode="onDecode"
        >
          <div v-if="savingScan" class="validation-success">Saving Scan</div>

          <div v-if="validationData" class="validation-data">
            <p class="decode-result text-black">
              <b>{{ result }}</b>
            </p>
            <button v-if="canSave" class="btn-black" @click="saveQR">
              <font-awesome-icon class="mr-3" icon="power-off" />Save
            </button>
            <button class="btn-red" @click="discardQR">
              <font-awesome-icon class="mr-3" icon="trash-alt" />Discard
            </button>
          </div>
        </qrcode-stream>
      </div>
    </div>
  </div>
</template>

<script>

import { QrcodeStream } from "vue-qrcode-reader";
import { v4, validate } from "uuid";
import EasyCamera from 'easy-vue-camera';
import { LocalDate, ChronoUnit } from '@js-joda/core';

const urlMatch = "https://sqr.pages.dev/#"




export default {

  name: "HelloWorld",

  components: { 
    QrcodeStream,
    EasyCamera
  },

  data () {
    return {
      //isValid: undefined,
      isValidData: false,
      isValidAsset: false,
      isSaving: false,
      camera: 'auto',
      takePhoto: false,
      needsPhoto: false,
      choiceNeedsPhoto: false,
      result: null,
      buttons: [],
      photo: undefined,
      canSave: false,
      selected: undefined,
      optionChosen: undefined,
      uuid: undefined
    }
  },

  computed: {
    validationData () {
      return this.isValidData;
    },

    canSaveUUID() {

      if ((this.needsPhoto || this.choiceNeedsPhoto) && this.photo === undefined) {
        return false;
      }

      return this.optionChosen !== undefined;
    },

    validationAsset () {
      return this.isValidAsset;
    },

    savingScan () {
      return this.isSaving === true
    },

    validationFailure () {
      return this.isValid === false
    },

    

  },

  methods: {

    processPicture(e) {
      this.$emit("photo", e)
      this.$refs.cam.close();
      this.takePhoto=false
    },

    closePhoto() {
      this.takePhoto = false;
    },

    resetValidationState () {
      this.isSaving = false;
      this.isValidAsset = false
      this.isValidData = false
      this.result = null;
      this.choiceNeedsPhoto = false
      this.needsPhoto = false
      this.canSave = false
      this.selected = undefined
      this.photo = undefined,
      this.optionChosen = undefined
      this.uuid = undefined
    },

    async discardQR() {
      this.turnCameraOn()
    },
    async saveQR() {

      
      if (this.result === null) {
        console.log("Null result")
        
        this.turnCameraOn()
        return;
      }

      this.isSaving = true;

      const canvas = document.querySelector("canvas.qrcode-stream-camera");

      let luuid = v4();

      let scan = {
        value: this.result,
        photo: undefined,
        date: new Date().toISOString(),
        picture: undefined,
        choice: this.selected,
        luuid: luuid,
      }

      if (this.photo != undefined) {
        scan.photo = this.photo;
      }
      else if (canvas) {
        scan.picture = canvas.toDataURL();
      }

      await this.$store.dispatch('saveScan', scan);

      var accessToken = await this.$auth.getTokenSilently();

      if (this.uuid) {
        await this.$api.post("uuid/"+this.uuid, accessToken, scan);
      }
      else {
        await this.$api.post("scan/", accessToken, scan);
      }

      
            
      this.turnCameraOn()

    },

    async optionChosenAction(event) {

      var button = event.target.value;
      
      this.selected = this.buttons[button][1];
      this.choiceNeedsPhoto=this.buttons[button][3];
        
      console.log(this.buttons[button]);
    },

    async onDecode (content) {
      this.turnCameraOff();
      if (!content.startsWith(urlMatch)) {
        this.result = content
        this.isValidAsset = false
        this.isValidData = true
        this.canSave = true
        return;
      }

      let uuid = content.substr(urlMatch.length);

      console.log(uuid);

      if (!validate(uuid)) {
        this.result = "Sorry, you don't have access to this asset"
        this.isValidAsset = false
        this.isValidData = true
        this.canSave = false
        return;
      }

      try {

        if (this.$isOnline(async (err, status) => {

          console.log(err)
          console.log(status)

          let data = null

          let odata = await this.$store.getters.getByUUID(uuid);

          if (status) {
            var accessToken = await this.$auth.getTokenSilently();
            console.log(accessToken)
            if (accessToken === undefined) {
              accessToken = await this.$auth.getTokenWithPopup();
              if (accessToken === undefined) {
                this.result = "Sorry, you need to be logged in to view this"
                this.isValidAsset = false
                this.isValidData = true
                this.canSave = false
                return;
              }
            }
            
            data = await this.$api.get("uuid/"+uuid, accessToken);

            console.log(data)

            data.uuid = uuid
            console.log(odata);
            if (odata !== undefined) {
              await this.$store.dispatch("deleteScan", odata.id);
            }
            await this.$store.dispatch("saveScan", data);
          }
          else {

            

            if (odata === undefined) {

              this.result = "Sorry, have to be online to have access to this asset for the first time"
              this.isValidAsset = false
              this.isValidData = true
              this.canSave = false
              return;
            }

            data = odata;



          }

          this.uuid = uuid;



          if (Object.prototype.hasOwnProperty.call(data.data.metadata, "photo")) {
            
            if (!Object.prototype.hasOwnProperty.call(data.details.details, "last_photo")) {
              this.needsPhoto = false; //true;
            }
            else {
              var d1 = LocalDate.parse(data.data.details.last_photo);
              var d2 = LocalDate.now();

              var diff = d1.until(d2, ChronoUnit.DAYS); 

              this.needsPhoto = (diff >= data.data.metadata.photo);
              
            }
          }
          
          console.log(data)
          this.result = data.data.metadata.name;
          this.buttons = data.details.details.buttons;
          this.isValidAsset = true
          this.isValidData = false

        
         
      
        }));
      }
      catch(e)  {
        console.log(e)
      }

    },

    turnCameraOn () {
      this.camera = 'auto'
      this.resetValidationState();
    },

    turnCameraOff () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  }
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-asset,
.validation-data {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}

.validation-asset {
  position: sticky;
  position: -webkit-sticky;
}

.btn-red {
  @apply inline-block text-sm px-4 py-2 leading-none border rounded text-white bg-red-900 border-white hover:border-transparent hover:text-black  hover:bg-white mt-4 lg:mt-0 disabled:bg-red-900 disabled:cursor-not-allowed disabled:text-white disabled:border-white disabled:opacity-50 ;
}

.btn-red-selected {
  @apply inline-block text-sm px-4 py-2 leading-none border-4 rounded text-black bg-red-500 border-white hover:border-transparent hover:text-black  hover:bg-white mt-4 lg:mt-0 font-extrabold ;
}

.btn-green {
  @apply inline-block text-sm px-4 py-2 leading-none border rounded text-white bg-green-900 border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0 disabled:bg-green-900 disabled:cursor-not-allowed disabled:text-white disabled:border-white disabled:opacity-50;
}

.btn-green-selected {
  @apply inline-block text-sm px-4 py-2 leading-none  border-4 rounded text-black bg-green-500 border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0 font-extrabold;
}

.btn-amber {
  @apply inline-block text-sm px-4 py-2 leading-none border rounded text-white bg-yellow-600 border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0 disabled:bg-yellow-900 disabled:cursor-not-allowed disabled:text-white disabled:border-white disabled:opacity-50;
}

.btn-amber-selected {
  @apply inline-block text-sm px-4 py-2 leading-none  border-4 rounded text-black bg-yellow-400 border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0 font-extrabold;
}

.btn-black {
  @apply inline-block text-sm px-4 py-2 leading-none border rounded text-white bg-black border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0;
}
</style>