import Vue from "vue";
import Vuex from 'vuex'
import App from "./App.vue";
import router from "./router";
import Axios from "./plugins/axios";
import { Auth0Plugin } from "./auth";

import "highlight.js/styles/github.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff, faQrcode, faSignInAlt, faTrashAlt, faCamera } from "@fortawesome/free-solid-svg-icons";



import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { domain, clientId, audience } from "./auth_config.json";
import api from './helpers/apicalls';
import './main.css'
import isOnline from 'browser-is-online';

import '@themesberg/flowbite';


import idb from './helpers/idb';

Vue.use(Vuex)
//Vue.use(EasyCamera)

const store = new Vuex.Store({
  state: {
    cats:[]
  },
  mutations: {

  },
  actions: {
    async deleteScan(context, scan) {
      await idb.delete(scan); 
    },
    
    async saveScan(context, cat) {
      await idb.set(cat);
    },
    
    
  },
  getters: {
    async listScans() {
      return await idb.list();
    },
    getByUUID:  () => async (uuid) => {
      return await idb.getIndexValue("uuid", uuid)
    } 
  }
})








import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;
Vue.prototype.$api = api;
Vue.prototype.$isOnline = isOnline;


Vue.config.productionTip = false


Vue.use(Axios);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  createAuth0Client: true,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

library.add(faLink, faUser, faPowerOff, faQrcode, faSignInAlt, faTrashAlt, faCamera);
Vue.component("font-awesome-icon", FontAwesomeIcon);








new Vue({
  router,
  render: h => h(App),
  store,
  api
}).$mount("#app");