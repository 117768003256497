<template>
  <div>
     <QRScanner />
     
  </div>
</template>

<script>
import QRScanner from '../components/QRScanner.vue'

export default {
  name: "scan",
  components: {
    QRScanner
  }
 
};
</script>

