<template>
 

   <div class=" rounded-xl p-8 border border-white">
         <img :src="$auth.user.picture" alt="user profile picture" class="w-32 h-full object-contain rounded-full mx-auto">
         <div class="pt-6 space-y-4">
            <h3 class="font-bold text-2xl mb-5 text-white">{{ $auth.user.name }}</h3>
            <p>This card has a header, body, and footer. This is where the content of the card goes.</p>
            <p class="lead text-muted text-white">{{ $auth.user.email }}</p>
            <button class="mt-5 rounded-lg px-4 py-2 bg-blue-500 text-blue-50 shadow hover:shadow-xl duration-300">
              Get Started
            </button>
          </div>
   
   </div>
   





    
    

    
  
</template>

<script>

export default {
  name: "profile",
  data() {
    return {
      token: false,
      apiMessage: null,
      executed: false
    };
  },
  async created() {
    this.token = await this.$auth.getTokenSilently();
  },
  methods: {
    async callApi() {
      const accessToken = await this.$auth.getTokenSilently();
      try {
        const { data } = await this.$http.get("https://backend.aarhus.workers.dev/api/wibble", {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        this.apiMessage = data;
        this.executed = true;
      } catch (e) {
          console.log(e);
        this.apiMessage = `Error: the server responded with '${e.response.status}: ${e.response.statusText}'`;
      }
    }
  }


}
</script>
