const DB_NAME = 'scanned_items';
const SCANNED_ITEMS = 'scanned_items';
const DB_VERSION = 2;
let DB;

import { openDB, /*deleteDB, wrap, unwrap*/ } from 'idb';


export default {

	async getDb() {
		return new Promise((resolve) => {

			console.log("Getting DB")

			if(DB) { return resolve(DB); }

			console.log('OPENING DB', DB_NAME);

			DB = openDB(DB_NAME, DB_VERSION, {

				upgrade(db, oldVersion, newVersion, transaction) {

					if(!db.objectStoreNames.contains(SCANNED_ITEMS)) {
						db.createObjectStore(SCANNED_ITEMS, { keyPath: "id", autoIncrement: true })
					}

					const storeName = transaction.objectStore(SCANNED_ITEMS)
					
					if(!storeName.indexNames.contains('uuid')) {
						storeName.createIndex('uuid', 'uuid', { unique: false });
					}

					if(!storeName.indexNames.contains('luuid')) {
						storeName.createIndex('luuid', 'luuid', { unique: false });
					}

					console.log("Upgraded DB")

					// …
				},
				blocked() {
					// …
				},
				blocking() {
					// …
				},
				terminated() {
					// …
				}
			});

			return resolve(DB); 
	
		});
		
	},
	async delete(key) {
		return (await this.getDb()).delete(SCANNED_ITEMS, key);
	},
	async get(key) {
		return (await this.getDb()).get(SCANNED_ITEMS, key);
	},
	async list() {
		return (await this.getDb()).getAll(SCANNED_ITEMS);
	},

	async getIndexValue(indexName, key) {
		return (await this.getDb()).getFromIndex(SCANNED_ITEMS, indexName, key);
	},

	async set(val) {
		return (await this.getDb()).add(SCANNED_ITEMS, val);
	}

}