<template>
  <div class="next-steps">
    <h2 class="text-white text-center">Welcome to Simple QR</h2>
    <p>Just want to scan?  Click the scan button below....</p>
  </div>
</template>

<script>
export default {
  name: "HomeContent"
};
</script>
