<template>
<footer class="h-auto bg-light text-center text-white p-3 bottom-0">
     
    

<nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">

  <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
    <div class="text-sm lg:flex-grow">
     
      <router-link :v-if="showButtons" to="/profile" class="inline-block text-sm px-4 py-2 mr-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0">
          <font-awesome-icon class="mr-3" icon="user" />Profile
      </router-link>
      
      <router-link to="/scan" class="inline-block text-sm px-4 py-2 mr-2 leading-none border rounded text-white border-white  hover:text-black hover:bg-white mt-4 lg:mt-0">
        <font-awesome-icon class="mr-3" icon="qrcode" />Scan
      </router-link>
      
      <button v-if="showButtons && !$auth.isAuthenticated" id="qsLoginBtn" class="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0" @click="login">
        <font-awesome-icon class="mr-3" icon="sign-in-alt" />Login
      </button>
      <button v-else-if="showButtons" id="qsLoginBtn" class="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white mt-4 lg:mt-0" @click="logout">
          <font-awesome-icon class="mr-3" icon="power-off" />Log Out
      </button>
      
    </div>
  </div>
</nav>
</footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin+"/"
      });
      if (this.$router.currentRoute.path !== "/" ) {
        this.$router.push({ path: "/" });
      }
    }
  },
  computed: {
    showButtons() {
      return !this.$auth.loading;
    }

  }
};
</script>

<style>
#mobileAuthNavBar {
  min-height: 125px;
  justify-content: space-between;
}
</style>
