<template>
  
    
    
    
    <home-content />
  
</template>

<script>

import HomeContent from "../components/HomeContent";
export default {
  name: "home",
  components: {
    
    HomeContent
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>

<style lang="scss" scoped>
.next-steps {
  .fa-link {
    margin-right: 5px;
  }
}
</style>