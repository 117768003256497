<template>
  
    <div id="app" class=" flex flex-col h-screen justify-between items-center">
      
 
  <router-link to="/" class="flex items-center flex-shrink-0 text-white mr-6">
    <svg class="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/></svg>
    <span class="font-semibold text-xl tracking-tight">Simple QR</span>
  </router-link>
  
 
    


    
    <error />
     <div class="mb-auto h-10 flex justify-center">
      <div class="mt-5">
        <router-view />
      </div>
    </div>
    <footer-nav />
    
  

    

   

    <div class="update-dialog text-white" v-if="prompt">
      <div class="update-dialog__content">
        A new version is found. You need to refresh to load it.
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="update"
        >
          Update
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="prompt = false"
        >
          Cancel
        </button>
      </div>
    </div>
    
  </div>
</template>

<script>


import Error from "./components/Error";
import FooterNav from "./components/Footer";

//const pako = require('pako');

const mdata = {
    "n": "Test item",
    "b": [
        [
            "OK","ok","green"
        ],
        [
            "Missing",
            "missing",
            "amber"
        ],
        [
            "Damaged",
            "damaged",
            "red"
        ]
    ],
    "u": "7e12ee35-5ea8-4f93-9083-483d440d23a9"
};

const compressed = JSON.stringify(mdata);
console.log(compressed)

export default {
  name: 'App',
  components: {
    FooterNav,
    
    Error
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  },
  data() {
    return {
      prompt: false,
      wibble: compressed,
      showQrGen: false,
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
